<template>
    <b-overlay :show="dataLoaded">
        <b-card title="Seznam sosesk" class="w-100">

            <b-row>
                <b-col cols="12" sm="6" class="text-center text-sm-left">
                    <b-button variant="primary" @click="$router.push({name: 'neighborhood-add'})" v-if="$hasPermissions($permissions.EditRealEstate)">Dodaj sosesko</b-button>
                </b-col>
            </b-row>

            <b-alert class="mt-1 p-1 text-center" show variant="warning" v-if="hasBeenDeleted">Po brisanju je potrebnih nekaj sekund, da je brisanje izvedeno. Če je seoseska po brisanju še vedno viden, ponovno naložite stran.</b-alert>

            <Table ref="table" class="mt-1" :fields="fields" route="/api/management/v1/neighborhoods" :fromElastic="false">

                <template #cell(main_photo)="row">
                    <b-img v-if="row.item.main_photo !== ''" fluid  :src="'/api/management/v1/image/thumbnail_' + row.item.main_photo" style="width: 100%; max-height: 70px; object-fit: contain;" alt="Image"/>
                </template>
                
                <template #cell(description)="row">
                    <div class="cut-text" v-html="row.item.description"/>
                </template>

                <template #cell(actions)="row" v-if="$hasPermissions($permissions.EditRealEstate)">
                    <div class="button-grid">
                        <!-- <b-button variant="primary"  :href="'/soseska/' + row.item.id + '?preview=true'" target="_blank"><fa icon="newspaper"/></b-button> -->
                        <b-button variant="warning mr-2" @click="$router.push({name: 'neighborhood-edit', params: {neighborhood_id: row.item.id}})"><fa icon="edit"/></b-button>
                        <b-button variant="danger" @click="deleteNeighboorhoodById(row.item.id)"><fa icon="trash"/></b-button>
                    </div>
                </template>
            </Table>
        </b-card>
    </b-overlay>
</template>

<script>

    import Table from '@/views/Components/Table'
    import {BButton, BCard, BCol, BImg, BOverlay, BRow, BAlert} from 'bootstrap-vue'
    export default {
        components: {
            Table,
            BOverlay,
            BCard,
            BRow,
            BCol,
            BButton,
            BImg,
            BAlert
        },
        data() {
            return {
                dataLoaded: false,
                items: [],
                fields: [
                    { key: 'main_photo', label: 'Slika', sortable: true, class: 'text-center' },
                    { key: 'title', label: 'Naslov', sortable: true, class: 'text-center title-table-data' },
                    { key: 'description', label: 'Opis', sortable: true, class: 'text-center' }
                ],
                hasBeenDeleted: false
            }
        },
        methods:{
            async deleteNeighboorhoodById(id) {
                this.hasBeenDeleted = false
                const deleteConfirmationResult = await this.$bvModal.msgBoxConfirm('Prosimo potrdite, da želite izbrisati sosesko.', {
                    size: 'sm',
                    okTitle: 'Da',
                    cancelTitle: 'Ne',
                    hideHeaderClose: false,
                    okVariant: 'danger',
                    cancelVariant: 'outline-secondary',
                    centered: true
                })
                if (!deleteConfirmationResult) return

                try {
                    await this.$http.delete(`/api/management/v1/neighborhoods/${id}`)
                    this.hasBeenDeleted = true
                    this.$printSuccess('Soseska je bila izbrisana')
                    setTimeout(() => {
                        this.$refs.table.search()
                    }, 1000)
                } catch (err) {
                    this.$printError(err)
                }

            }
        },
        mounted() {
            if (this.$hasPermissions(this.$permissions.EditRealEstate)) {
                this.fields.push({ key: 'actions', label: '', class: 'text-center' })
            }
        }
    }
</script>

<style scoped>

    .cut-text{
        max-width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .button-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      column-gap: 10px;
      row-gap: 10px;
    }
</style>